
<template>
    <div class="main_v8">
        <h1>Archive Manager</h1>
        Archive Server Database Status
        <div class="statuswindow">
            <div v-if="archiverange.min==0">{{ archiverange.status }}</div>
            <div v-if="archiverange.min!=0">{{ archiverange.status }} from<br> 
                <div style="font-size: 16px;">{{ archiverange.min }} to {{ archiverange.max }}</div>
            </div>
            <br>
            <div v-if="showpercent">Percent Uploaded: {{ percentuploaded }}%</div>
            <div v-if="showinsert"><img width="120" src="../../public/img/boeing/spin.gif" /><br>Inserting Archive into database...</div>
            <div v-if="showpurge"><img width="120" src="../../public/img/boeing/spin.gif" /><br>Clearing data from Archive database...</div>
        </div>
        <br>
        <button class="buttonclass" type="button" @click="open">Import Local Archive File</button><br>
        <br>
        <button class="buttonclass" @click="PurgeDatabase">Clear Database on Archive Server</button>
        <br><br>
        List of archives available<br> 
        to download to your computer:
        <div class="downloadlist">
            <table>
                <tr v-for="item in downloadlist"><td class="tdclass"><a class="downloadbutton" :href="item.url" download>{{ item.name }}</a></td></tr>
            </table>
        </div>
    </div>
    <statusupdater 
      :src="statusurl"
      :scanrate="scanrate"
      :controls=false
      @on-data="UpdateStatus">
    </statusupdater>
</template>
  
  <script setup>
    import { ref } from 'vue';
    import statusupdater from '../components/livedata/data2.vue';
    import { useFileDialog } from '@vueuse/core'

    let downloadlist = ref([]);
    let archiverange = ref("");
    let percentuploaded = ref("");
    let showpercent = ref(false);
    let showinsert = ref(false);
    let showpurge = ref(false);
    let currentfile = "";
    let bdlApiPath = "https://archive.boeing.dreamlifter.wave7.co/screens/apps/archiver/scripts/";
    const scanrate = ref(5000);
    const statusurl = bdlApiPath+"getcurrentrangev8.php?simple";
    
    function UpdateStatus(data) {
        archiverange.value = data;
    }

    function completeHandler(event) {
      console.log("CompleteHandler",event.target.responseText);
      showpercent.value = false;
      InsertArchive();
    }

    function InsertArchive() {
        showinsert.value = true;
        const url = bdlApiPath + 'insertarchivev8.php?fn=' + currentfile;
        fetch(url, {
            method: 'Post',
        }).then((res) => res.json()).then((response) => {
            console.log("insert archive result",response);
            showinsert.value = false;
            archiverange.value.status = "updating...";
        }).catch((error) => {
            console.log("error inserting archive data:", error);
            showinsert.value = false;
        });
    }

    function PurgeDatabase() {
        showpurge.value = true;
        const url = bdlApiPath + 'purgev8.php';
        fetch(url, {
            method: 'Post',
        }).then((res) => res.json()).then((response) => {
            console.log("purge result",response);
            showpurge.value = false;
            archiverange.value.status = "updating...";
        }).catch((error) => {
            console.log("error purging data:", error);
            showpurge.value = false;
        });
    }

    function abortHandler(event) {
        console.log("AbortHandler",event.target.responseText);
    }

    function progressHandler(event) {
        const percent = (event.loaded/event.total)*100;
        percentuploaded.value = Math.round(percent);
        console.log("ProgressHandler",Math.round(percent));
    }

    const { files, open, reset, onChange } = useFileDialog({
        accept: 'zip/*', 
        directory: false,
    })

    onChange((files) => {
        console.log("FILES",files);
        showpercent.value = true;
        UploadFile(files[0])
    })

    async function UploadFile(file) {
        const formdata = new FormData();
        const ajax = new XMLHttpRequest();
        currentfile = file.name;
        formdata.append('file',file);
        formdata.append('enctype',"multipart/form-data");
        ajax.upload.addEventListener('progress', progressHandler, false);
        ajax.addEventListener('load', completeHandler, false);
        ajax.addEventListener('abort', abortHandler, false);
        ajax.open('POST', 'https://archive.boeing.dreamlifter.wave7.co/scripts/file-uploadv8.php');
        ajax.send(formdata);
    }

    function CreateDownloadList() {
        let now = new Date();
        let today = now.getUTCDate();
        let s = 6;

        if(today > 1) {
            s=0;
        } else { 
            s=1;
        }

        for(var i=s;i<13;i++)
        {    
            let dateObj = new Date();
            dateObj.setDate(1);
            dateObj.setMonth(dateObj.getMonth() - (6+i));
            let month = dateObj.getUTCMonth() + 1;
            if(month<10) month="0"+month;
            let day = dateObj.getUTCDate();
            let year = dateObj.getUTCFullYear();
            const item = {};
            item.url = "https://boeing.dreamlifter.wave7.co/uploads/archives/archive-"+year+"-"+month+".zip"; 
            item.name = "Download " + year + "-" + month;
            downloadlist.value.push(item);
        }
    }
  
    CreateDownloadList();
</script>
  
<style lang="scss" scoped>
  .statuswindow {
    height:180px;
    border: 1px solid #333;
    width:100vw;
    max-width: 360px;
    padding:10px;
    border-radius: 6px;
    background-color: white;
  }
  .downloadlist {
    height:300px;
    overflow-y: auto;
    border: 1px solid #333;
    width:100vw;
    max-width: 340px;
    padding:20px;
    border-radius: 6px;
    background-color: white;
  }
  .downloadbutton {
    width: 250px;
    padding:10px;
    text-decoration: none;
    border:1px solid #333;
    border-radius: 4px;
    margin:10px;
    background-color: #eee;
    color:#444;
  }
  .tdclass {
    padding:15px;
  }
  .buttonclass {
    text-align: center;
    line-height: 45px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 8px;
    border: solid 1px #006C3E;
    background-color: #006C3E;
    color: #fff;
    cursor: pointer;
    padding:10px;
  }
</style>
  
  
  